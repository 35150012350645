import KnowledgebaseContainer from "../../Component/Miscellaneous/Knowledgebase/Knowledgebase"

const Knowledgebase = () => {
  return (
    <div className='page-body'>
      <KnowledgebaseContainer />
    </div>
  )
}

export default Knowledgebase