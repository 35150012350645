export class ConfigDB {
  static data = {
    settings: {
      mix_layout_type: 'light',
      layout_type:"ltr",
      layout_class: 'compact-wrapper',
      sidebar: {
        iconType: '',
      },
    },
    color: {
      primary_color: `(--theme-default)`,
      secondary_color: `(--theme-secondary)`,
    }
  };
}
export default ConfigDB;