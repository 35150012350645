export const whishListProduct = [
    {
      name: "Women Top",
      image: "01.jpg",
    },
    {
      name: "Women Shorts",
      image: "02.jpg",
    },
    {
      name: "Cyclamen",
      image: "03.jpg",
    },
    {
      name: "Men Dashed Denim Jacket",
      image: "04.jpg",
    },
    {
      name: "Blue Shirt",
      image: "05.jpg",
    },
    {
      name: "Red Shirt",
      image: "06.jpg",
    },
    {
      name: "Men's Shirt",
      image: "07.jpg",
    },
    {
      name: "Men's Shirt",
      image: "08.jpg",
    },
    {
      name: "Women's Dress",
      image: "05.jpg",
    },
    {
      name: "Denim Jacket",
      image: "04.jpg",
    },
    {
      name: "Women's T-shirt",
      image: "03.jpg",
    },
    {
      name: "Men's Jacket",
      image: "02.jpg",
    }
  ];