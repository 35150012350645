import { Col, Container, Row } from "reactstrap";
import { P, SVG } from "../../AbstractElements";

const Footer = () => {
  return (
    <footer className='footer'>
      <Container fluid>
        <Row>
          <Col md='6' className='footer-copyright'>
            <P className='mb-0'>Copyright 2024 © HDM.</P>
          </Col>
          <Col md='6'></Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
