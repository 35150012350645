import ContainerProject from '../../../Component/Dashboard/Project/Project'

const Project = () => {
  return (
    <div className='page-body'>
      <ContainerProject />
    </div>
  )
}

export default Project