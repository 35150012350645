
export const badgeData = [
  {
    text: "Use the",
    code: ".badge ",
  },
  {
    text: "utility class to make more badges.",
  },
];

export const badgesContext = [
  {
    text: "primary",
  },
  {
    text: "secondary",
  },
  {
    text: "tertiary",
  },
  {
    text: "success",
  },
  {
    text: "info",
  },
  {
    text: "warning",
  },
  {
    text: "danger",
  },
  {
    text: "light",
    className:"text-dark"
  },
  {
    text: "dark",
    className: "tag-pills-sm-mb",
  },
];

export const pillsData = [
  { text: "Use the", code: ".rounded-pill" },
  {
    text: " utility class to make badges more rounded.",
  },
];

export const numberData = [
  {
    text: "Use the",
    code: ".badge",
  },
  {
    text: " utility class to make badges more rounded with a larger border-radius.",
  },
];

export const numberContext = [
  {
    text: "1",
    className: "badge-primary",
  },
  {
    text: "2",
    className: "badge-secondary",
  },
  {
    text: "3",
    className: "badge-tertiary",
  },
  {
    text: "4",
    className: "badge-success",
  },
  {
    text: "5",
    className: "badge-info",
  },
  {
    text: "6",
    className: "badge-warning",
  },
  {
    text: "7",
    className: "badge-danger",
  },
  {
    text: "8",
    className: "badge-light text-dark",
  },
  {
    text: "9",
    className: "badge-dark",
  },
];

export const roundedData = [
  {
    text: "Use the",
    code: ".rounded-pill",
  },
  {
    text: " utility class to make badges more rounded with a larger border-radius.",
  },
];

export const roundedContext = [
  {
    text: "1",
    className: "rounded-circle badge-p-space badge-primary",
  },
  {
    text: "2",
    className: "rounded-circle badge-p-space badge-secondary",
  },
  {
    text: "3",
    className: "rounded-circle badge-p-space badge-tertiary",
  },
  {
    text: "4",
    className: "rounded-circle badge-p-space badge-success",
  },
  {
    text: "5",
    className: "rounded-circle badge-p-space badge-info",
  },
  {
    text: "6",
    className: "rounded-circle badge-p-space badge-warning",
  },
  {
    text: "7",
    className: "rounded-circle badge-p-space badge-danger",
  },
  {
    text: "8",
    className: "rounded-circle badge-p-space badge-light text-dark",
  },
  {
    text: "9",
    className: "rounded-circle badge-p-space badge-dark",
  },
];

export const iconData = [
  {
    text: "Use the",
    code: ".badge ",
  },
  {
    text: " utility class to make more icons.",
  },
];

export const iconContext = [
  {
    className: "b-ln-height badge-primary p-2",
    icon: "activity",
    iconClass: "feather",
  },
  {
    className: "b-ln-height badge-secondary p-2",
    icon: "headphones",
    iconClass: "feather",
  },
  {
    className: "b-ln-height badge-tertiary p-2",
    icon: "link",
    iconClass: "feather",
  },
  {
    className: "b-ln-height badge-success p-2",
    icon: "github",
    iconClass: "feather",
  },
  {
    className: "b-ln-height badge-info p-2",
    icon: "award",
    iconClass: "feather",
  },
  {
    className: "b-ln-height badge-warning p-2",
    icon: "dollar-sign",
    iconClass: "feather",
  },
  {
    className: "b-ln-height badge-danger p-2",
    icon: "heart",
    iconClass: "feather",
  },
  {
    className: "b-ln-height badge-light text-dark p-2",
    icon: "mail",
    iconClass: "feather",
  },
  {
    className: "b-ln-height badge-dark p-2",
    icon: "airplay",
    iconClass: "feather",
  },
];

export const roundedPillData = [
  {
    text: "Use the",
    code: ".badge",
  },
  {
    text: " utility class to make more icons. ",
  },
];

export const roundedPillContext = [
  {
    className: "rounded-pill p-2 badge-primary",
    icon: "activity",
    iconClass: "feather",
  },
  {
    className: "rounded-pill p-2 badge-secondary",
    icon: "headphones",
    iconClass: "feather",
  },
  {
    className: "rounded-pill p-2 badge-tertiary",
    icon: "link",
    iconClass: "feather",
  },
  {
    className: "rounded-pill p-2 badge-success",
    icon: "github",
    iconClass: "feather",
  },
  {
    className: "rounded-pill p-2 badge-info",
    icon: "award",
    iconClass: "feather",
  },
  {
    className: "rounded-pill p-2 badge-warning",
    icon: "dollar-sign",
    iconClass: "feather",
  },
  {
    className: "rounded-pill p-2 badge-danger",
    icon: "heart",
    iconClass: "feather",
  },
  {
    className: "rounded-pill p-2 badge-light text-dark",
    icon: "mail",
    iconClass: "feather",
  },
  {
    className: "rounded-pill p-2 badge-dark",
    icon: "airplay",
    iconClass: "feather",
  },
];

export const badgeExData = [
  {
    text: "All html headings,",
    code: "<h1>",
  },
  { text: ".. ", code: "<h6>" },
  {
    text: "are available in .badge tags. ",
  },
];

export const badgeButtonData = [
  {
    text: "All html button,",
    code: ".btn , .badge",
  },
  {
    text: " are available. ",
  },
];

export const badgeButtonList = [
  {
    className: "secondary",
    title: "Notifications",
    icon: "bell",
  },
  {
    className: "tertiary",
    title: "Update available",
    icon: "settings",
  },
  {
    className: "success",
    title: "Playing Now",
    icon: "music",
  },
  {
    className: "info",
    title: "1.2 GB Used",
    icon: "alert-triangle",
  },
];

export const touchspinBadgesData = [
  {
    text: "Use",
    code: ".main-touchspin,.increment-touchspin",
  },
  {
    text: "for change the value.",
  },
];

export const animatedBadgesData = [
  {
    text: "Use the",
    code: ".bg-common-*, animated-badge, .dote-*",
  },
  {
    text: "for add animation on badges.",
  },
];

export const animatedBadgesContextData = [
  {
    color: "primary",
    icon: "Home",
  },
  {
    color: "secondary",
    icon: "Star",
  },
  {
    color: "tertiary",
    icon: "Bag",
  },
  {
    color: "info",
    icon: "Bell",
  },
];

export const centerBadgesData = [
  {
    text: "Use the",
    code: ".badge, .badge-* , center-badge",
  },
  { text: "for center mode badges." },
];

export const centerBadgesContextData = [
  {
    color: "info",
    badge: "New",
    icon: "Category",
  },
  {
    color: "success",
    badge: "Update",
    icon: "Chart",
  },
  {
    color: "primary",
    badge: "Latest",
    icon: "Ticket-star",
  },
  {
    color: "danger",
    badge: "Error",
    icon: "Danger",
  },
];
