
const ColorHeadingTableBody = () => {
  return (
    <tbody>
      <tr>
        <td><code>&lt;h1&gt;&lt;/h1&gt;</code></td>
        <td><h1><span className="font-primary"> Heading1 </span> SubHeading</h1></td>
      </tr>
      <tr>
        <td><code>&lt;h2&gt;&lt;/h2&gt;</code></td>
        <td><h2><span className="font-secondary"> Heading1 </span> SubHeading</h2></td>
      </tr>
      <tr>
        <td><code>&lt;h3&gt;&lt;/h3&gt;</code></td>
        <td><h3><span className="font-success"> Heading1 </span> SubHeading</h3></td>
      </tr>
      <tr>
        <td><code>&lt;h4&gt;&lt;/h4&gt;</code></td>
        <td><h4><span className="font-info"> Heading1 </span> SubHeading</h4></td>
      </tr>
      <tr>
        <td><code>&lt;h5&gt;&lt;/h5&gt;</code></td>
        <td><h5><span className="font-warning"> Heading1 </span> SubHeading</h5></td>
      </tr>
      <tr>
        <td className="pb-0"><code>&lt;h6&gt;&lt;/h6&gt;</code></td>
        <td className="pb-0"><h5><span className="font-danger"> Heading1 </span> SubHeading</h5></td>
      </tr>
    </tbody>
  )
}

export default ColorHeadingTableBody