import IconlySpriteContainer from "../../Component/Icons/IconlySprite/IconlySprite";

const IconlySprite = () => {
  return (
    <div className='page-body'>
      <IconlySpriteContainer />
    </div>
  );
};

export default IconlySprite;
