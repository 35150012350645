import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { H3, H5 } from "../../../../AbstractElements";
import bullhornService from "../../../../Service/bullhornService";
import ProjectedRevenue from "./ProjectedRevenue";

interface Job {
  id: number;
  clientCorporation: {
    id: number;
    name: string;
  };
  projectedRev: number;
  status: string;
}
interface ClientData {
  color: string;
  count: string;
  title: string;
  class: string;
  total: number;
}
interface Error {
  errorMessage: string;
}

const RevenueForecast = () => {
  const [data, setData] = useState<Job[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const colors = ["primary", "secondary", "success", "danger", "warning"];

  const fetchData = async () => {
    setLoading(false);
    try {
      const results = await bullhornService.getBookedRevenueFY();

      setData(results);
      setLoading(false);
    } catch (error: any) {
      console.error("Error fetching data:", error); // Debug: Log error
      setError({ errorMessage: error.message });
      setLoading(false);
    }
  };

  const groupJobsByStatus = (jobs: Job[]): ClientData[] => {
    const statusMap: { [status: string]: number } = {};
    jobs.forEach((job) => {
      if (statusMap[job.status]) {
        statusMap[job.status]++;
      } else {
        statusMap[job.status] = 1;
      }
    });

    let colorIndex = 0;

    return Object.keys(statusMap).map((status) => {
      const data: ClientData = {
        color: colors[colorIndex % colors.length],
        count: statusMap[status].toString(),
        title: status,
        class: status.toLowerCase().replace(/\s+/g, "-"),
        total: 0,
      };
      colorIndex++;
      return data;
    });
  };

  const groupJobsByCorporation = (jobs: any[]): { [name: string]: number } => {
    const corporationMap: { [name: string]: number } = {};

    jobs.forEach((job) => {
      if (corporationMap[job.clientCorporation.name]) {
        corporationMap[job.clientCorporation.name] += job.customFloat3;
      } else {
        corporationMap[job.clientCorporation.name] = job.customFloat3;
      }
    });

    return corporationMap;
  };

  const getTotalProjectedRev = (jobs: any[]) => {
    return jobs.reduce((total, job) => total + (job.customFloat3 || 0), 0);
  };

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [loading]);

  if (loading) {
    return <div>Loading Revenue Forecast...</div>;
  }

  if (error) {
    return <div>Error: {error.errorMessage}</div>;
  }

  const groupedData = data ? groupJobsByStatus(data) : [];
  const totalProjectedRev = data
    ? getTotalProjectedRev(data).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
    : 0;

  return (
    <Col xs='12'>
      <Row>
        <Col xs='12'>
          <Card className='client-card card-hover'>
            <CardBody>
              <Row>
                <Col xs='12' className='custom-width-1'>
                  <H3 className={`font-primary`}>Total Projected Revenue</H3>
                  <H5 className='f-w-600'>{totalProjectedRev}</H5>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          {data && (
            <ProjectedRevenue groupedJobs={groupJobsByCorporation(data)} />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          <H5 className='f-w-600'>Jobs</H5>
        </Col>
      </Row>
      <Row>
        {groupedData.map((item, i) => (
          <Col xs='12' key={i}>
            <Card className='client-card card-hover'>
              <CardBody>
                <Row>
                  <Col xs='12' className='custom-width-1'>
                    <H3 className={`font-${item.color}`}>{item.count}</H3>
                    <H5 className='f-w-600'>{item.title}</H5>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default RevenueForecast;
