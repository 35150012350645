import ContainerDefault from "../../../Component/Dashboard/Default/Default"

const Default = () => {
  return (
    <div className='page-body'>
      <ContainerDefault />
    </div>
  )
}

export default Default