import ContainerGeneral from '../../../Component/Widgets/General/General'

const General = () => {
  return (
    <div className='page-body'>
      <ContainerGeneral />
    </div>
  )
}

export default General