import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { clientAbbr } from "../../../../Data/Dashboard/DashboardChartData";

interface RevProps {
  groupedJobs: { [name: string]: number };
}

const ProjectedRevenue: React.FC<RevProps> = (props) => {
  const primary = "#43B9B2";
  const secondary = "#c280d2";

  const entriesArray: [string, number][] = Object.entries(props.groupedJobs);

  const loadedKeys: string[] = [];
  const loadedValues: number[] = [];

  const checkAndOutputValue = (key: string) => {
    if (key in clientAbbr) {
      const value = clientAbbr[key as keyof typeof clientAbbr]; // Type assertion added here
      return value;
    } else {
      return key;
    }
  };

  entriesArray.forEach(([key, value]) => {
    loadedKeys.push(checkAndOutputValue(key));

    loadedValues.push(Number(value.toFixed(2)));
  });

  const revenueChartData = {
    chart: {
      type: "line" as "line", // Explicitly specifying the type
    },
    series: [
      {
        name: "Revenue",
        data: loadedValues,
      },
    ],
    xaxis: {
      name: "Clients",
      categories: loadedKeys,
    },
  };

  return (
    <Col xs='12'>
      <Card className='investing-card'>
        <CardBody className='p-0'>
          <div className='investing'>
            <ReactApexChart
              options={revenueChartData}
              series={revenueChartData.series}
              height={500}
              type='bar' // Ensure the type here matches the chart type
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
export default ProjectedRevenue;
