import axios from 'axios';

const REACT_APP_BULLHORN_CLIENT_ID = '024f2332-770c-4c18-8b67-9bb4f576fdd1';
const REACT_APP_BULLHORN_CLIENT_SECRET = '7MDs6M4xWlK6AaLdaUmxSK9e';
const REACT_APP_BULLHORN_USERNAME = 'healthdatamovers.rest.api';
const REACT_APP_BULLHORN_PASSWORD = 'kamxe4-zeztix-wIcqow!';
const REACT_APP_OAUTH_URL = 'https://auth-east.bullhornstaffing.com/oauth';
const REACT_APP_REST_URL = 'https://rest-east.bullhornstaffing.com/rest-services';
const REACT_APP_REDIRECT_URI = 'https://buphagus.healthdatamovers.com/callback';
const REACT_APP_STATE = 'EdNCMn1KembQO7Ons5KzKUI6y';
const ERROR_WEBHOOK_URL = '/zap';
const BOOKED_REV_QUERY=`search/JobOrder?query=dateAdded:[20240606 TO 29990101]&fields=id,customFloat3,customText39,customText37,customText38,clientCorporation,dateAdded,status,startDate,dateEnd,payRate`;

function formatDateForBullhorn(date: Date): string {
    const yyyy = date.getUTCFullYear();
    const MM = String(date.getUTCMonth() + 1).padStart(2, '0');
    const dd = String(date.getUTCDate()).padStart(2, '0');

    return `${yyyy}${MM}${dd}`;
}

interface CustomError extends Error {
    response?: {
        data: any;
        status: number;
        headers: any;
    };
}

const getLoginInfo = async (username: string) => {
    try {
        const response = await axios.get(`${REACT_APP_REST_URL}/loginInfo?username=${username}`);
        return response.data;
    } catch (error) {
        const customError = error as CustomError;
        console.error('Error getting login info:', customError);
        sendAppStatusToZapier({
            'event': 'Error',
            'message': `Error getting login info: ${customError.message}`
        });
    }
};

const sendAppStatusToZapier = async (message: { event: string; message: string; }) => {
    try {
        console.log(message)
        //await axios.post(ERROR_WEBHOOK_URL, message);
    } catch (error) {
        const customError = error as CustomError;
        console.error('Error sending message to Slack:', customError);
    }
};

const getAuthorizationCode = async (loginInfo: any) => {
    const authUrl = `${REACT_APP_OAUTH_URL}/authorize?client_id=${REACT_APP_BULLHORN_CLIENT_ID}&response_type=code&action=Login&username=${REACT_APP_BULLHORN_USERNAME}&password=${REACT_APP_BULLHORN_PASSWORD}&redirect_uri=${REACT_APP_REDIRECT_URI}&state=${REACT_APP_STATE}`;
    try {
        const response = await axios.get(authUrl, { maxRedirects: 0 });
        return response.data;
    } catch (error) {
        const customError = error as CustomError;
        if (customError.response && customError.response.status === 302) {
            const redirectUrl = customError.response.headers['location'];
            const urlParams = new URLSearchParams(new URL(redirectUrl).search);
            const authCode = urlParams.get('code');
            return authCode;
        } else {
            console.error('Error getting authorization code:', customError);
            sendAppStatusToZapier({
                'event': 'Error',
                'message': `Error getting authorization code: ${customError.message}`
            });
        }
    }
};

const fetchBullhornJobs = async (sessionToken: { restUrl: string, BhRestToken: string }) => {
    const fromDate = formatDateForBullhorn(new Date()); // Replace with actual date logic
    const fields = ['id', 'title', 'status', 'clientCorporation', 'dateAdded', 'description', 'salary'].join(',');
    const query = `search/JobOrder?query=dateAdded:[${fromDate} TO 29990101]&fields=${fields}`;
    console.log(sessionToken.BhRestToken, `${sessionToken.restUrl}${query}`);
    try {
        const response = await axios.get(`${sessionToken.restUrl}${query}`, {
            headers: {
                'BhRestToken': sessionToken.BhRestToken
            }
        });

        return response.data.data;
    } catch (error) {
        const customError = error as CustomError;
        if (customError.response) {
            console.error('Error response data:', customError.response.data);
            sendAppStatusToZapier({
                'event': 'Error',
                'message': `Error fetching Bullhorn Jobs data: ${customError.message}`
            });
        }
        return {
                'event': 'Error',
                'message': `Error fetching Bullhorn Jobs data: ${customError.message}`
            }
    }
};

const getAccessToken = async (authCode: string) => {
    const tokenUrl = `https://auth-east.bullhornstaffing.com/oauth/token?grant_type=authorization_code&code=${authCode}&client_id=${REACT_APP_BULLHORN_CLIENT_ID}&client_secret=${REACT_APP_BULLHORN_CLIENT_SECRET}&redirect_uri=${REACT_APP_REDIRECT_URI}`;

    try {
        const response = await axios.post(tokenUrl);
        return response.data;
    } catch (error) {
        const customError = error as CustomError;
        console.error('Error getting access token:', customError.message);
        sendAppStatusToZapier({
            'event': 'Error',
            'message': `Error getting access token: ${customError.message}`
        });
    }
};

const getSessionToken = async (restUrl: string, accessToken: string) => {
    const loginUrl = `${restUrl}/login?version=*&access_token=${accessToken}`;

    try {
        const response = await axios.post(loginUrl);
        return response.data;
    } catch (error) {
        const customError = error as CustomError;
        console.error('Error getting session token:', customError.message);
        sendAppStatusToZapier({
            'event': 'Error',
            'message': `Error getting session token: ${customError.message}`
        });
    }
};

const bullhornService = {
    getBookedRevenueFY: async () => {
        let start = 0;
        let allResults: any[] = [];
        let hasMoreResults = true;
        const query = BOOKED_REV_QUERY;
        const loginInfo = await getLoginInfo(REACT_APP_BULLHORN_USERNAME);
        const authCode = await getAuthorizationCode(loginInfo);
        if (!authCode) {
            throw new Error('Authorization code not retrieved');
        }
        const accessTokenData = await getAccessToken(authCode.code);
        if (!accessTokenData || !accessTokenData.access_token) {
            throw new Error('Access token not retrieved');
        }
        const sessionToken = await getSessionToken(loginInfo.restUrl, accessTokenData.access_token);
        
        while (hasMoreResults) {
            try {
                const response = await axios.get(`/proxy?${sessionToken.restUrl}${query}&start=${start}`, {
                    headers: {
                        'BhRestToken': sessionToken.BhRestToken
                    }
                });

                const jobs = response.data.data;
                if (jobs.length > 0) {
                    let filteredJobs = jobs.filter((job: any) => {
                        return job.customFloat3 > 0;
                    });
                    allResults.push(...filteredJobs);
                    start += jobs.length;
                } else {
                    hasMoreResults = false;
                }
            } catch (error) {
                const customError = error as CustomError;
                if (customError.response) {
                    //console.error('Error response data:', customError.response.data);
                    console.log(sessionToken.BhRestToken, `${sessionToken.restUrl}${query}`);
                    // sendAppStatusToZapier({
                    //     'event': 'Error',
                    //     'message': `Error fetching Booked Revenue data: ${customError.message}`
                    // });
                    // Removing Loging to Zapier
                }
            }
        }
        return allResults;
    },

    getJobById: async (id: string) => {
        try {
            const response = await axios.get(`${REACT_APP_REST_URL}/jobs/${id}`);
            return response.data;
        } catch (error) {
            const customError = error as CustomError;
            console.error(`Error fetching job with id ${id}:`, customError);
            throw customError;
        }
    },

    // Add other API methods here...
};

export default bullhornService;
