import { ToastContainer } from "react-toastify";
import RouterData from "./Routes";

function App() {
  // console.log(process.env.REACT_APP_BULLHORN_USERNAME);
  // const bullhornUser = process.env.REACT_APP_BULLHORN_USERNAME;
  return (
    <>
      <RouterData />
      <ToastContainer />
    </>
  );
}

export default App;
